
@import '../../styles/theme.scss';

$bgInactif : rgb(150,150,150);
$bgExterne :  rgb(200,200,255);


.tableAppels{
	td{
		padding: 3px;
		font-size: 0.85em;
	}
	.td-edit{
		text-align: center;
		cursor: pointer;
	}
	.td-humanDate {
		color:red;
		&.today{
			color: green;
		}
	}


	.semaine-impaire{
		background-color: rgb(240,240,255);
	}
	.semaine-paire{
		background-color: rgb(255,240,240);
	}


	.tr-today{
		background-color: rgb(200,255,200) !important;
		font-size: 1.1em;

	}

}


/*

.pageDpAppelsIndex{
	padding: 0px;
	margin: 0px;
	//background-color: cyan;


	.ligne-appel{
		//background-color: yellow;

		border-bottom: 1px solid $C1;
		border-top: 1px solid $C1;
		padding: 0px !important;
		margin: 0px !important;
		font-size: 0.9em;
		.cell {
			border-right: 1px solid $C1;
		}
		&.inactif{
			background-color: $bgInactif ;
		}
		&.externe{
			background-color:$bgExterne;
		}

		.humanDate{
			padding-left: 10px;
			font-size: 0.8em;
			color:red;
			&.today{
				font-weight: bold;
				color:darkGreen;
			}
		}
	}


}
*/
