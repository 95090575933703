@import '../../styles/theme.scss';
$bgInactif : rgb(150,150,150);
$bgExterne :  rgb(200,200,255);


.legende-externe{
	background-color:$bgExterne;
}

.legende-inactif{
	background-color:$bgInactif;
}
li{
	margin-bottom: 10px;
}

.tableEleves{

	tbody{
		td{
			font-size: 0.8em;
		}

		.tr-externe{
			background-color:$bgExterne;
		}

		.tr-aed{
			background-color: yellow;
		}

		.tr-inactif {
			background-color: $bgInactif ;
		}



	}

	.joursPrio{
		font-size: 0.7em;
		span{
			margin-right: 15px;
		}
	}


}
