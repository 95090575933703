@import '../../styles/theme.scss';

.app-navbar{
	background-color: $C1;
	width: 100% !important;

	.navbar-brand{
	    font-size: 1em;
		img{
			width:30px;
			height:30px;
		}
		.app-title{
	        font-size: 0.8em !important;
	 		padding-left: 5px;
		}
	}

	.navbar-collapse{

	//background-color: lime !important;
	}

	.container-fluid{
		//background-color: cyan !important;
		width:100%!important;
		margin-left: 0px;
		//	width: 100% !important;
		//	padding: 0px !important;
		//	margin: 0px !important;

	}
	.container{
		//background-color: orange !important;
		width:100%!important;
		margin-left: 0px;
		//	width: 100% !important;
		//	padding: 0px !important;
		//	margin: 0px !important;

	}

   .nav-link{
        font-size: 0.9em !important;
    }


	#user-basic-nav-dropdown{
		//background-color: red !important;
		margin-left: auto !important;
	}

}
