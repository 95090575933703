@import '../../styles/theme.scss';


li{
	margin: 0px;
}

.nomPrenomClasse{
	min-width: 300px;
	display: inline-block;
}
