// https://www.w3schools.com/w3css/w3css_color_generator.asp

$C1l4: #ebf6fe;
$C1l3: #bee1fa;
$C1l2: #7cc3f5;
$C1l1: #3ba5f0;
// $Cl0: #1083d4;
$C1: #3b5097;
// $Cl0: #0a5184;
$C1d1: #094876;
// $Cl0: #083f67;
$Cld2: #073658;
$C1d3: #062d4a;


/*
$theme-colors: (
  "primary": $C1,
  "warning": orange,
  "danger": red,
);

@import '~bootstrap/scss/bootstrap.scss';
*/
