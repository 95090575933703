@import '../../styles/theme.scss';


.formAppel{
	max-width: 600px !important;
	//background-color: red !important;

	.formHeader{
		h3{color:$C1;}
	}

	label{
		font-weight: bold;
		color: $C1;
	}
}
