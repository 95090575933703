@import '../../styles/theme.scss';

.Login {
	color:$C1d1;
	form {
  		text-align: center;
      	margin: 0 auto;
  		.app-title{
  			margin-top: 20px;
  			margin-bottom: 20px;
  		}
    }
}


@media all and (min-width: 320px) {
  	.Login {
    	padding: 60px 0;
		//background-color: green;
		form {
	      	margin: 0 auto;
	      	max-width: 320px;
	  		.app-logo{
	  			width: 40%;
	  		}
	  		.app-title{
	  			font-size: 1.5em;
	  		}
	    }
  	}
}

@media all and (max-width: 319px) {
  	.Login {
    	padding: 10px 0;
		//background-color: red;
		form {
	      	margin: 0 auto;
	      	width: 100%;
	  		.app-logo{
	  			width: 60%;
	  		}
	  		.app-title{
	  			font-size: 1.1em;
	  		}
	    }
  	}
}
