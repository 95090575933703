@import './theme.scss';
@import './zx.scss';

.App {
	padding: 0px;
	//background-color: red;
	//background-color: yellow;	//

	.app-page{
		//background-color:$C1l2;
		//background-color: orange;	//
		padding: 10px;
		//padding: 0px !important;	//
		//margin: 0px !important;
		//margin: 5px;


		.page-header{
			background-color: white;
			margin-bottom: 5px;
			border-bottom: 1px solid $C1;
			// background-color:blue;
			.page-title{
				color: $C1;
				margin:0px;
				padding: 10px;
				text-align: center;
				font-size: 1.2em;
			}
		}


		.page-content{
			background-color: white;
			//background-color: green;	//██████████████████████████████████████████████████████
			padding: 10px;
			//padding: 0px !important;
			margin: 0px;
			&.page-content-transparant{
				background-color: inherit !important;
				//background-color: pink	 !important;//██████████████████████████████████████████████████████



			}
		}
	}

}



.todo{
	background-color: rgba(255,0,0,0.2);
	border: 1px solid red;
	h1{ color:red; }
}

.help{
	background-color: rgba(0,0,255,0.2);
	border: 1px solid blue;
	h1{ color:blue; }
}


.todo, .help{
	padding: 10px;
	padding-left: 20px;
	h1{ font-size: 1.4em;	margin-bottom: 20px;}
	h2{ font-size: 1.2em;	}
	font-size: 0.8em;
}
