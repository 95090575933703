@import '../../styles/theme.scss';

$bgInactif : rgb(150,150,150);
$bgExterne :  rgb(200,200,255);


@media (max-width : 900px) {
    .pageDpAppelShow ,
	.tableAppelContainer,
	.page-content
	{
		padding: 0px !important;
	}
}



.pageDpAppelShow{
	//background-color: cyan;


	.tableAppelContainer{
		//background-color: red;
	}


	.stickySearch{
		background-color: white;
		padding: 5px;
		//border:1px solid black;
	}



	.table-appel-resume{
		margin-top: 30px;
		//background-color: green;
		tr{
			border: 1px solid black !important;

			&.trVide{
				//background-color: rgb(120,150,200) ;
				//color:white!important;
				font-weight: bold;
				font-size: 1.4em;
				height: 40px;
				text-align: center;
			}


			&.trCommentaire{
				//background-color: rgb(120,150,200) ;
				//color:white!important;
				th{
					font-weight: normal !important;
				}

				text-align: left !important;
				color:red;
				font-size: 1em;
			}



			&.trEleve{

				text-align: center;

				&:hover{
					background-color: rgb(120,150,200) !important;
				}

				&.trService1{
					//background-color: rgb(50,50,255);
					//color:white;
				}
				&.trService2{
					//background-color: rgb(0,150,0);
					//color:white;
				}



				.service1{	color: blue;	}
				.service2{	color: green;	}




				.sansCarte{
					background-color: rgb(255,100,100);
				}

				.colEleveNom{
					text-align: left;
				}


			}



			.serviceTheo1{	background-color: rgb(100,100,255);		}
			.serviceTheo2{	background-color: rgb(100,255,100);		}


			th, td{
				padding: 1px;
				border: 1px solid black !important;
			}


		}
	}

	.table-appel-mail{
		max-width:900px;
		margin: auto;

		border: 1px solid black;
		td{
			border: 1px solid black;
			font-size: 0.8em;
			padding: 0px;
		}

		.helpStar{
			color: red;
			margin-right: 20px;
			margin-left: 20px;
		}
		.tr-present{
			//background-color: rgb(200,200,255);
		}
		.tr-absent{
		//	background-color: rgb(200,200,200);
		}

		.tr-sansCarte{
		//	background-color: rgb(255,200,200);
		}

		.trVide{
			background-color: rgb(200,200,200);
		}

	}

	.table-faire-appel{
		background-color:white;
		margin-top: 30px;

		//background-color: green;
		tr{
			border: 1px solid black !important;

			&.trVide{
				font-weight: bold;
				font-size: 1.4em;
				height: 40px;
				text-align: center;
			}


			&.trCommentaire{
				th{
					font-weight: normal !important;
				}
				text-align: left !important;
				color:red;
				font-size: 1em;
			}



			&.trEleve{
				text-align: center;
				&:hover{ background-color: rgb(120,150,200) !important;}

				&.trService1{
					background-color: rgb(50,50,255);
					color:white;
				}
				&.trService2{
					background-color: rgb(0,150,0);
					color:white;
				}

				.service1{	color: blue;	}
				.service2{	color: green;	}

				.sansCarte{background-color: rgb(255,100,100);	}

				.colEleveNom{text-align: left;}


			}



			.serviceTheo1{	background-color: rgb(100,100,255);		}
			.serviceTheo2{	background-color: rgb(100,255,100);		}


			th, td{
				padding: 1px;
				border: 1px solid black !important;
			}


		}

		.trInactif, .trExterne{
			background-color: rgb(200,200,200);
		}
		.trService1, .trService2, .smallTr{
			font-size: 0.9em;

			.bt-service-1, bt-service-2, .btsanscarte{
				padding: 0px !important;
				font-size: 0.8em !important;
			}
			//display: none;
		}
		.trGreen{
			background-color: rgb(200,255,200);
		}


	}





	.btSearchToggle{
		position: fixed;
		z-index: 200;
		bottom: 10px;
		right: 10px;
		width:50px;
		height: 50px;
		padding-left: 12px;
		padding-top: 4px;
		border-radius: 50%;
		cursor: pointer;
		font-size: 1.5em;
		color:white;

		&.btSearchToggle-on{
			background-color: $C1;
		}

		&.btSearchToggle-off{
			background-color: green;
		}
	}
	.btShowAllToggle{
		position: fixed;
		z-index: 200;
		bottom: 10px;
		left: 10px;
		width:50px;
		height: 50px;
		padding-left: 12px;
		padding-top: 4px;
		border-radius: 50%;
		cursor: pointer;
		font-size: 1.5em;
		color:white;

		&.btShowAllToggle-on{
			background-color: $C1;
		}

		&.btShowAllToggle-off{
			background-color: red;
		}
	}

	.zoneResumeSansCarte{
		border:1px solid red;
		h5{	color:red;	}
	}
	.zoneResumeManquants{
		border:1px solid blue;
		h5{	color:blue;	}
	}
	.zoneResumeSansCarte, .zoneResumeManquants{
		padding: 5px;
		margin-top: 5px;
		h5{
			text-decoration: underline;
			//	background-color: green;
		}
		li{
			font-size: 0.8em;
		}

	}



}
